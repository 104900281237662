@import "../../../public/assets/style/variables.scss";

.container {
  display: flex;
  align-items: center;
  img {
    width: $logo_size;
    margin-right: 60px;
    height: auto;
  }
}

.menu-icon {
  display: none;
  align-items: center;
  cursor: pointer;
  font-size: 25px;
  width: 106px;

  svg {
    color: white;
  }
}

.routes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 45px;
}

.menu-list__item__link {
  cursor: pointer;
  font-weight: 300;
  font-size: 18px;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  color: $navbar_text_color !important;

  &:hover {
    color: $hover_color !important;
  }
  &::before {
    transition: 300ms;
    content: "";
    height: 2px;
    position: absolute;
    background-color: $hover_color;
    width: 0%;
    bottom: 10px;
  }
  &:hover::before {
    width: 100%;
  }
}

.menu-list__item__link.active-link {
  color: $active_color !important;
  &::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: $active_color;
    left: 0;
    bottom: 10px;
  }
  &:hover::before {
    width: 0;
  }
}

.dropdown-item {
  position: relative;
  &::before {
    transition: 300ms;
    content: "";
    height: 2px;
    position: absolute;
    background-color: $navbar_text_color;
    width: 0%;
    bottom: -8px;
  }
  &:hover::before {
    width: 100%;
  }
}

.dropdown-item.active-link {
  color: $active_color !important;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    left: 0;
    bottom: -8px;
    background-color: $active_color !important;
  }
}

.reseller-dropdown {
  background-color: $navbar_background_color;
  padding: 5px 10px;
  border-radius: 5px;

  li {
    color: $navbar_text_color;
    a {
      font-size: 14px;
    }

    &:hover {
      background-color: transparent;
    }

    &::before {
      transition: 300ms;
      content: "";
      height: 1px;
      background-color: #fff;
      width: 0%;
      position: absolute;
      bottom: 0;
    }
    &:hover::before:not(.dropdown-item.active:hover::before) {
      width: 70%;
    }
  }
}

.log-button {
  line-height: 5;
}

.nav-drawer {
  background-color: $navbar_background_color;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 50px;

  a {
    font-size: 18px;
    color: $navbar_text_color;
    margin-top: 20px;

    &:hover {
      opacity: 0.8;
    }
  }
}

.navbar-login-lang-section {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 20px;
}

.btn {
  min-width: 140px;
  border: transparent;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  color: $buttons_text_color !important;

  &:hover {
    background-color: $hover-color !important;
  }

  &:disabled {
    background-color: #b6b6b6a1;

    &:hover {
      background-color: #b6b6b6a1;
    }
  }
}

@media screen and (max-width: 1300px) {
  .routes {
    gap: 30px;
  }
  .btn {
    min-width: 70px;
    height: 27px;
    font-size: 10px;
  }
}

@media screen and (max-width: 1180px) {
  .routes {
    display: none;
  }

  .container {
    img {
      width: 55px;
      margin: 0 !important;
    }
  }

  .menu-icon {
    display: flex;
  }

  .logo {
    width: 36px !important;
  }
}

@media screen and (max-width: 772px) {
  .menu-icon {
    padding: 15px;
  }

}

